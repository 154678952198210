export const Spinner =
    // eslint-disable-next-line mobx/missing-observer
    () => {
        return (
            <div className="block h-4 w-4 animate-spin-fast rounded-full border-[0.125rem] border-border-spinner border-l-[rgb(25,25,25)] p-0.5"></div>
        )
    }

export const ActionSpinner =
    // eslint-disable-next-line mobx/missing-observer
    () => {
        return (
            <div className="block h-4 w-4 animate-spin-fast rounded-full border-[0.125rem] border-action-2 border-l-action-1 p-0.5"></div>
        )
    }
