import { IconDiscord } from '@/components/Icons'
import { Spinner } from '@/components/Spinner'
import { supabase } from '@/config'
import { SessionContext } from '@/contexts/SessionContext'
import validator from 'email-validator'
import { observer } from 'mobx-react-lite'
import { useContext, useMemo, useState } from 'react'
import { FcGoogle } from 'react-icons/fc'
import { Navigate, useLocation } from 'react-router'
import { toast } from 'sonner'

const Login = observer(() => {
    const { session } = useContext(SessionContext)

    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    // const [showOtp, setShowOtp] = useState(false)
    // get previous page
    const location = useLocation()
    const { from } = location.state || { from: '' }

    const redirectTo = useMemo(() => {
        return `${window.location.origin}${from || ''}`
    }, [from, window.location.origin])

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!validator.validate(email)) {
            toast.error(
                'Invalid email! Make sure you enter a valid email address.'
            )
            return
        }

        setLoading(true)
        const signInPromise = async () => {
            try {
                await supabase.auth.signInWithOtp({
                    email,
                    options: {
                        emailRedirectTo: redirectTo,
                    },
                })
            } catch (error) {
                toast.error("Couldn't send login link")
            } finally {
                setLoading(false)
            }
        }

        await toast.promise(signInPromise(), {
            loading: 'Sending login link...',
            success: "Email sent! Check your spam folder if you don't see it.",
            error: 'Error, try again!',
        })
    }

    // const handleOtpSend = async (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault()

    //     setLoading(true)
    //     const signInPromise = async () => {
    //         try {
    //             const { data, error } = await supabase.auth.signInWithOtp({
    //                 email: email,
    //                 options: {
    //                     emailRedirectTo: loginRedirect,
    //                 },
    //             })
    //         } catch (error) {
    //             toast.error("Couldn't send login link")
    //         } finally {
    //             setLoading(false)
    //             setShowOtp(true)
    //         }
    //     }

    //     await toast.promise(signInPromise(), {
    //         loading: 'Sending OTP...',
    //         success: 'Email sent! Check your spam folder if you do not see it.',
    //         error: 'Error, try again!',
    //     })
    // }

    // const handleOtpLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault()

    //     setLoading(true)
    //     const signInPromise = async () => {
    //         try {
    //             const { data, error } = await supabase.auth.verifyOtp({
    //                 email: email,
    //                 token: password,
    //                 type: 'magiclink',
    //             })
    //             if (error) {
    //                 const { data, error } = await supabase.auth.verifyOtp({
    //                     email: email,
    //                     token: password,
    //                     type: 'signup',
    //                 })
    //                 throw error
    //             }
    //         } finally {
    //             setLoading(false)
    //         }
    //     }
    //     await toast.promise(signInPromise(), {
    //         loading: 'Logging in...',
    //         success: 'Logged in!',
    //         error: 'Error, try again!',
    //     })
    // }

    if (session) {
        return <Navigate to="/" replace />
    }

    const buttonStyle =
        'border-border-2 border select-none transition-background hover:text-text-1 select-none transition-border transition-background hover:border-border-1 backdrop-blur-sm bg-bg-1 text-text-2 hover:bg-bg-2 mt-8 flex w-full items-center justify-center space-x-3 rounded-md p-2 lg:mt-0'

    return (
        <div className="flex h-screen w-screen items-center justify-center p-4 md:p-8 lg:p-16">
            {/* <div className="bg-bg-3 border-border-2 relative flex h-full w-full flex-col items-center justify-center rounded-2xl border-2 p-2"> */}
            {/* <div className="border-border-1 absolute inset-2 z-0 overflow-hidden rounded-xl border-2"> */}
            {/* <StarField /> */}
            {/* </div> */}
            <div className="z-10 w-full max-w-sm space-y-2 rounded-lg p-4">
                <h1 className="mb-8 select-none text-center text-2xl font-semibold">
                    Sign in to Orchard
                </h1>
                <button
                    className={buttonStyle}
                    aria-live="polite"
                    onClick={async () => {
                        const oauth = await supabase.auth.signInWithOAuth({
                            provider: 'google',
                            options: {
                                redirectTo,
                                skipBrowserRedirect: true,
                            },
                        })
                        if (oauth.data.url) {
                            window.location.href = oauth.data.url
                        }
                    }}
                >
                    <FcGoogle className="h-6 w-6" />
                    <span className="font-medium">Continue with Google</span>
                </button>

                <button
                    className={buttonStyle}
                    aria-live="polite"
                    onClick={async () => {
                        const oauth = await supabase.auth.signInWithOAuth({
                            provider: 'discord',
                            options: {
                                redirectTo,
                                skipBrowserRedirect: true,
                            },
                        })
                        if (oauth.data.url) {
                            window.location.href = oauth.data.url
                        }
                    }}
                >
                    <IconDiscord className="h-6 w-6" />
                    <span className="font-medium">Continue with Discord</span>
                </button>

                <div className="flex items-center justify-center">
                    <hr className="border-border-1 grow"></hr>
                    <span className="text-text-3 m-4 select-none">or</span>
                    <hr className="border-border-1 grow"></hr>
                </div>

                <form
                    className="flex flex-col space-y-2"
                    onSubmit={handleLogin}
                >
                    <input
                        className={
                            'bg-bg-2 border-border-1 focus:border-border-1 text-text-1 rounded-md border px-3 py-1.5 outline-none backdrop-blur-sm disabled:cursor-not-allowed'
                        }
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={loading}
                    />
                    <button
                        className={`enabled:hover:bg-bg-2 transition-background transition-border enabled:hover:border-border-1 hover:text-text-1 border-border-2 bg-bg-1 text-text-2 flex select-none justify-center rounded-md border px-2 py-2 font-semibold backdrop-blur-sm disabled:cursor-not-allowed`}
                        aria-live="polite"
                    >
                        {!loading ? 'Send magic link' : <Spinner />}
                    </button>
                </form>
            </div>
            {/* </div> */}
        </div>
    )
})

export default Login
