import { IconBaseProps, IconType } from 'react-icons'
import {
    AiFillCheckCircle,
    AiOutlineFolder,
    AiOutlineInfoCircle,
    AiOutlineMinusCircle,
    AiOutlinePicCenter,
    AiOutlinePicLeft,
    AiOutlinePicRight,
    AiOutlineUserAdd,
} from 'react-icons/ai'
import {
    BiBookContent,
    BiCheckbox,
    BiCheckboxSquare,
    BiFontFamily,
    BiHomeAlt,
    BiSelection,
} from 'react-icons/bi'
import {
    BsArrowReturnLeft,
    BsArrowsAngleContract,
    BsArrowsAngleExpand,
    BsClockHistory,
    BsDot,
    BsFileEarmarkPdf,
    BsFillReplyFill,
    BsLightbulb,
    BsLightningCharge,
    BsPeople,
    BsSortAlphaDown,
    BsThreeDotsVertical,
    BsVectorPen,
} from 'react-icons/bs'
import { CgChevronDoubleLeft } from 'react-icons/cg'
import { FaDiscord, FaTwitter } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'
import {
    FiFastForward,
    FiHelpCircle,
    FiRepeat,
    FiSettings,
    FiZoomIn,
    FiZoomOut,
} from 'react-icons/fi'
import { GiExpand } from 'react-icons/gi'
import {
    HiOutlineChevronRight,
    HiOutlineDocument,
    HiPlus,
} from 'react-icons/hi'
import { HiOutlineFolderPlus } from 'react-icons/hi2'
import { ImLifebuoy } from 'react-icons/im'
import { IoMdRemoveCircleOutline } from 'react-icons/io'
import {
    IoArrowBack,
    IoArrowForward,
    IoImageOutline,
    IoList,
    IoSearch,
    IoSwapVertical,
    IoText,
    IoTrashOutline,
    IoWarning,
} from 'react-icons/io5'
import {
    MdExpandLess,
    MdExpandMore,
    MdOpenInNew,
    MdOutlineLibraryAdd,
    MdOutlineShortText,
    MdRefresh,
    MdSchool,
    MdAddToDrive,
} from 'react-icons/md'
import {
    RiAlignCenter,
    RiAlignJustify,
    RiAlignLeft,
    RiAlignRight,
    RiArrowDownLine,
    RiArrowGoBackFill,
    RiArrowGoForwardFill,
    RiArrowUpLine,
    RiBold,
    RiBracesLine,
    RiCheckLine,
    RiCloseLine,
    RiCodeSSlashFill,
    RiCommandLine,
    RiDoubleQuotesL,
    RiDriveLine,
    RiEdit2Line,
    RiFileCopyLine,
    RiFileDownloadLine,
    RiFolderAddFill,
    RiH1,
    RiH2,
    RiH3,
    RiH4,
    RiH5,
    RiH6,
    RiItalic,
    RiLinkUnlinkM,
    RiMenuLine,
    RiMoonClearFill,
    RiPencilFill,
    RiPlayLine,
    RiPlayListAddLine,
    RiPushpinLine,
    RiQuillPenLine,
    RiSendPlane2Fill,
    RiStarFill,
    RiStrikethrough,
    RiSunLine,
    RiUnderline,
    RiWechatLine,
} from 'react-icons/ri'
import { SiMicrosoftword } from 'react-icons/si'
import {
    TbArrowsDiagonalMinimize2,
    TbBlockquote,
    TbCloudUpload,
    TbColumnInsertLeft,
    TbColumnInsertRight,
    TbDoorExit,
    TbLink,
    TbMath,
    TbPaperclip,
    TbRowInsertBottom,
    TbRowInsertTop,
    TbSelector,
    TbTable,
    TbTextPlus,
} from 'react-icons/tb'
import { TiArrowForward } from 'react-icons/ti'
import { VscDebugRerun, VscNewFile, VscSplitHorizontal } from 'react-icons/vsc'

const wrapIcon = (Icon: IconType) => {
    const icon = (props: IconBaseProps) => <Icon {...props} />
    return icon
}

export const IconAlphaSort = wrapIcon(BsSortAlphaDown)
export const IconTime = wrapIcon(BsClockHistory)
export const IconHome = wrapIcon(BiHomeAlt)
export const IconBold = wrapIcon(RiBold)
export const IconItalic = wrapIcon(RiItalic)
export const IconUnderline = wrapIcon(RiUnderline)
export const IconStrike = wrapIcon(RiStrikethrough)
export const IconUndo = wrapIcon(RiArrowGoBackFill)
export const IconRedo = wrapIcon(RiArrowGoForwardFill)
export const IconCode = wrapIcon(RiCodeSSlashFill)
export const IconQuote = wrapIcon(RiDoubleQuotesL)
export const IconAlignLeft = wrapIcon(RiAlignLeft)
export const IconAlignCenter = wrapIcon(RiAlignCenter)
export const IconAlignRight = wrapIcon(RiAlignRight)
export const IconAlignJustify = wrapIcon(RiAlignJustify)
export const IconMoon = wrapIcon(RiMoonClearFill)
export const IconSun = wrapIcon(RiSunLine)
export const IconH1 = wrapIcon(RiH1)
export const IconH2 = wrapIcon(RiH2)
export const IconH3 = wrapIcon(RiH3)
export const IconH4 = wrapIcon(RiH4)
export const IconH5 = wrapIcon(RiH5)
export const IconH6 = wrapIcon(RiH6)
export const IconExtend = wrapIcon(TbTextPlus)
export const IconEdit = wrapIcon(BsVectorPen)
export const IconNew = wrapIcon(RiEdit2Line)
export const IconSelector = wrapIcon(TbSelector)
export const IconCheck = wrapIcon(RiCheckLine)
export const IconText = wrapIcon(IoText)
export const IconCommand = wrapIcon(RiCommandLine)
export const IconGoogle = wrapIcon(FcGoogle)
export const IconCopy = wrapIcon(RiFileCopyLine)
export const IconLink = wrapIcon(TbLink)
export const IconUnlink = wrapIcon(RiLinkUnlinkM)
export const IconImage = wrapIcon(IoImageOutline)
export const IconPlus = wrapIcon(HiPlus)
export const IconClose = wrapIcon(RiCloseLine)
export const IconMultiply = wrapIcon(RiCloseLine)
export const IconMinimize = wrapIcon(TbArrowsDiagonalMinimize2)
export const IconMenu = wrapIcon(RiMenuLine)
export const IconEnter = wrapIcon(BsArrowReturnLeft)
export const IconArrowDown = wrapIcon(RiArrowDownLine)
export const IconArrowUp = wrapIcon(RiArrowUpLine)
export const IconMinus = wrapIcon(AiOutlineMinusCircle)
export const IconStar = wrapIcon(RiStarFill)
export const IconRun = wrapIcon(RiPlayLine)
export const IconPeople = wrapIcon(BsPeople)
export const IconQuill = wrapIcon(RiQuillPenLine)
export const IconDownload = wrapIcon(RiFileDownloadLine)
export const IconBraces = wrapIcon(RiBracesLine)
export const IconReplace = wrapIcon(FiRepeat)
export const IconInsert = wrapIcon(RiPlayListAddLine)
export const IconUpload = wrapIcon(TbCloudUpload)
export const IconTrash = wrapIcon(IoTrashOutline)
export const IconBulb = wrapIcon(BsLightbulb)
export const IconSettings = wrapIcon(FiSettings)
export const IconOpen = wrapIcon(MdOpenInNew)
export const IconOpenPane = wrapIcon(VscSplitHorizontal)
export const IconCheckCircle = wrapIcon(AiFillCheckCircle)
export const IconPin = wrapIcon(RiPushpinLine)
export const IconExpand = wrapIcon(MdExpandMore)
export const IconExpandWindow = wrapIcon(BsArrowsAngleExpand)
export const IconCollapse = wrapIcon(MdExpandLess)
export const IconCollapseWindow = wrapIcon(BsArrowsAngleContract)
export const IconRerun = wrapIcon(VscDebugRerun)
export const IconSend = wrapIcon(RiSendPlane2Fill)
export const IconPencil = wrapIcon(RiPencilFill)
export const IconLightning = wrapIcon(BsLightningCharge)
export const IconShortText = wrapIcon(MdOutlineShortText)
export const IconSummary = wrapIcon(TbBlockquote)
export const IconRefresh = wrapIcon(MdRefresh)
export const IconConversation = wrapIcon(RiWechatLine)
export const IconSchool = wrapIcon(MdSchool)
export const IconSwap = wrapIcon(IoSwapVertical)
export const IconPicLeft = wrapIcon(AiOutlinePicLeft)
export const IconPicRight = wrapIcon(AiOutlinePicRight)
export const IconPicCenter = wrapIcon(AiOutlinePicCenter)
export const IconTable = wrapIcon(TbTable)
export const IconAddColumnBefore = wrapIcon(TbColumnInsertLeft)
export const IconAddColumnAfter = wrapIcon(TbColumnInsertRight)
export const IconAddRowBefore = wrapIcon(TbRowInsertTop)
export const IconAddRowAfter = wrapIcon(TbRowInsertBottom)
export const IconCollapseSidebar = wrapIcon(CgChevronDoubleLeft)
export const IconExit = wrapIcon(TbDoorExit)
export const IconDiscord = wrapIcon(FaDiscord)
export const IconTwitter = wrapIcon(FaTwitter)
export const IconSelection = wrapIcon(BiSelection)
export const IconCreateFolder = wrapIcon(HiOutlineFolderPlus)
export const IconAddFolder = wrapIcon(RiFolderAddFill)
export const IconCreateDocument = wrapIcon(VscNewFile)
export const IconExpandFolderClosed = wrapIcon(HiOutlineChevronRight)
export const IconDocument = wrapIcon(HiOutlineDocument)
export const IconArrowLeft = wrapIcon(IoArrowBack)
export const IconArrowRight = wrapIcon(IoArrowForward)
export const IconResource = wrapIcon(TbPaperclip)
export const IconHelp = wrapIcon(FiHelpCircle)
export const IconShare = wrapIcon(AiOutlineUserAdd)
export const IconDocSettings = wrapIcon(BiFontFamily)
export const IconVerticalDots = wrapIcon(BsThreeDotsVertical)
export const IconExpandPage = wrapIcon(GiExpand)
export const IconMath = wrapIcon(TbMath)
export const IconFeedback = wrapIcon(ImLifebuoy)
export const IconUncheckedCheckbox = wrapIcon(BiCheckbox)
export const IconCheckedCheckbox = wrapIcon(BiCheckboxSquare)
export const IconFolder = wrapIcon(AiOutlineFolder)
export const IconWarning = wrapIcon(IoWarning)
export const IconRemove = wrapIcon(IoMdRemoveCircleOutline)
export const IconSearch = wrapIcon(IoSearch)
export const IconNewTab = wrapIcon(TiArrowForward)
export const IconPdf = wrapIcon(BsFileEarmarkPdf)
export const IconWord = wrapIcon(SiMicrosoftword)
export const IconGoogleDrive = wrapIcon(RiDriveLine)
export const IconInfo = wrapIcon(AiOutlineInfoCircle)
export const IconAddWeb = wrapIcon(MdOutlineLibraryAdd)
export const IconFast = wrapIcon(FiFastForward)
export const IconZoomIn = wrapIcon(FiZoomIn)
export const IconZoomOut = wrapIcon(FiZoomOut)
export const IconList = wrapIcon(IoList)
export const IconReply = wrapIcon(BsFillReplyFill)
export const IconLongText = wrapIcon(TbTextPlus)
export const IconOutline = wrapIcon(BiBookContent)
export const IconDot = wrapIcon(BsDot)
export const IconAddTo = wrapIcon(MdAddToDrive)

// Template Icons
export const IconOrchard: React.FC =
    // eslint-disable-next-line mobx/missing-observer
    () => (
        <div className="flex h-full w-full flex-none items-center justify-center overflow-hidden rounded">
            <svg
                width="202"
                height="202"
                viewBox="0 0 202 202"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    width="201.234"
                    height="201.234"
                    rx="15.0456"
                    fill="#1D1D1D"
                />
                <path
                    d="M100.516 163.328C90.9323 163.328 82.3907 161.349 74.8907 157.391C67.4949 153.432 61.6616 148.016 57.3908 141.141C53.2241 134.162 51.1408 126.193 51.1408 117.235C51.1408 107.86 53.2241 99.5263 57.3908 92.2346C61.6616 84.943 67.547 79.2138 75.047 75.0472C82.5469 70.8805 91.0365 68.7972 100.516 68.7972C109.891 68.7972 118.12 70.7764 125.203 74.7347C132.286 78.5888 137.807 83.9534 141.765 90.8284C145.828 97.7034 147.859 105.724 147.859 114.891C147.859 122.912 146.401 129.943 143.484 135.984C140.568 142.026 136.713 147.078 131.922 151.141C127.234 155.203 122.13 158.276 116.609 160.359C111.088 162.339 105.724 163.328 100.516 163.328ZM101.609 151.766C106.505 151.766 110.568 150.516 113.797 148.016C117.026 145.412 119.422 141.662 120.984 136.766C122.651 131.766 123.484 125.776 123.484 118.797C123.484 110.776 122.443 103.797 120.359 97.8596C118.38 91.9221 115.516 87.3388 111.766 84.1097C108.12 80.7763 103.745 79.1097 98.6406 79.1097C91.5573 79.1097 85.9844 82.0784 81.9219 88.0159C77.8595 93.9534 75.8282 102.183 75.8282 112.703C75.8282 120.412 76.922 127.182 79.1094 133.016C81.2969 138.849 84.3178 143.432 88.1719 146.766C92.1302 150.099 96.6094 151.766 101.609 151.766Z"
                    fill="white"
                />
                <g clipPath="url(#clip0_920_2682)">
                    <path
                        d="M130.045 24.2839C119.581 23.0623 114.152 25.9943 111.326 29.0432C106.015 34.7692 106.865 45.5944 105.675 49.6419C103.625 51.5541 102.212 53.9868 101.139 56.5258C100.544 55.4104 99.8214 54.3799 98.9184 53.5194C97.8986 43.2572 96.4857 41.345 95.0409 39.6772C93.0437 37.4675 88.9006 35.4278 81.9742 36.2458C81.1987 36.2458 80.54 36.7982 80.2001 37.4675C79.8708 38.243 79.977 39.0185 80.4232 39.6772C81.6449 41.2282 82.6328 43.0023 83.4083 44.7658C83.6314 45.2119 85.5649 50.7467 88.3907 52.627C89.6124 53.6256 91.0147 54.3162 92.3744 54.6242C96.9319 55.6547 98.3554 60.159 99.014 62.4855L97.7923 73.555C97.6861 74.7767 98.4616 75.8816 99.6727 75.9878C100.862 76.1153 101.797 75.2123 101.882 74.1075C102.923 61.6781 105.42 55.6228 108.862 52.627C110.19 51.3522 112.623 50.4174 114.174 49.8543C116.054 49.0788 118.094 48.2077 119.931 46.9754C123.968 44.2558 126.688 36.2352 127.017 35.5766C128.239 32.8039 129.79 30.148 131.553 27.7153C132.881 25.5269 130.863 24.1883 130.045 24.2839ZM87.9657 44.3196C87.7427 43.8734 86.5316 41.3344 86.0854 40.4421C92.3107 40.3146 93.1712 44.9782 93.6174 46.423C93.8405 47.3047 94.0636 49.0788 94.2866 50.7361C94.0529 50.7361 89.9842 50.2049 87.9657 44.3196ZM122.29 35.789C119.23 41.685 119.9 43.5335 110.445 46.6355C111.167 37.7862 113.217 33.3457 114.652 31.6884C116.086 30.148 119.931 27.8428 126.05 28.2677C125.062 30.0418 122.619 35.1304 122.29 35.789Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_920_2682">
                        <rect
                            width="51.9998"
                            height="51.9998"
                            fill="white"
                            transform="translate(80 24)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )

export const IconTextSelection: React.FC =
    // eslint-disable-next-line mobx/missing-observer
    () => (
        <div className="bg-chat-editor-light flex h-6 w-6 shrink-0 items-center justify-center rounded-md font-serif">
            T
        </div>
    )

export enum TemplateIconKey {
    WriteAboutThis = 'WriteAboutThis',
    MoreConcise = 'MoreConcise',
    Rephrase = 'Rephrase',
    AssessClaims = 'AssessClaims',
    Summarize = 'Summarize',
    Custom = 'Custom',
    TakeNotes = 'TakeNotes',
    Rerun = 'Rerun',
}

export const templateIconMap: Record<
    TemplateIconKey,
    (props: IconBaseProps) => JSX.Element
> = {
    [TemplateIconKey.WriteAboutThis]: IconPencil,
    [TemplateIconKey.MoreConcise]: IconShortText,
    [TemplateIconKey.Rephrase]: IconRefresh,
    [TemplateIconKey.AssessClaims]: IconConversation,
    [TemplateIconKey.Summarize]: IconSummary,
    [TemplateIconKey.Custom]: IconLightning,
    [TemplateIconKey.TakeNotes]: IconSchool,
    [TemplateIconKey.Rerun]: IconRerun,
}
