import { lazy } from 'react'

import Login from '@/pages/Login'

import { SessionProvider } from '@/contexts/SessionContext'
import { observer } from 'mobx-react-lite'
import { HotkeysProvider } from 'react-hotkeys-hook'
import { Route, Routes } from 'react-router-dom'
import { Toaster } from 'sonner'

const Docs = lazy(() => import('@/pages/Docs'))
const ReadonlyDoc = lazy(() => import('@/pages/ReadonlyDoc'))
const ReadonlyChat = lazy(() => import('@/pages/ReadonlyChat'))

const App = observer(function App() {
    return (
        <SessionProvider>
            <HotkeysProvider>
                <Toaster
                    toastOptions={{
                        className:
                            'border border-border-1 bg-bg-main shadow text-text-main',
                    }}
                    position="bottom-left"
                    richColors
                    expand={true}
                />
                <Routes>
                    <Route path="/view/:documentId" element={<ReadonlyDoc />} />
                    <Route path="/c/:chatId" element={<ReadonlyChat />} />
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/:workspaceId?/:documentId?"
                        element={<Docs />}
                    />
                    <Route path="*" element={<Login />} />
                </Routes>
            </HotkeysProvider>
        </SessionProvider>
    )
})

export default App
