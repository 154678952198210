import App from '@/App'
import '@/index.css'
import '@/math.css'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import posthog from 'posthog-js'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

const IS_DEV = import.meta.env.DEV

!IS_DEV &&
    posthog.init('phc_aaZXbo3D9dy1ZbXdQzVweURn8OxOlxNBtFKTkb60TTQ', {
        api_host: 'https://analytics.orchard.ink',
        autocapture: true,
        disable_session_recording: false,
    })

Sentry.init({
    dsn: 'https://b3b583fe9fbe408796fe502956329cc4@o4504119928291328.ingest.sentry.io/4504119929536512',
    tunnel: IS_DEV
        ? 'http://localhost:1234/errors'
        : 'https://api.orchard.ink/errors',
    integrations: [
        new BrowserTracing(),
        new posthog.SentryIntegration(
            posthog,
            'vllg', // our Sentry organization slug
            4504119929536512 // our Sentry project ID, which is the end of the DSN
        ),
        new Sentry.Replay({
            // Additional SDK configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: false,
            // compression breaks envelope forwarding for now
            // see https://github.com/getsentry/sentry/discussions/39460
            useCompression: false,
        }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
})

function __openLinks() {
    document.querySelector('body')!.addEventListener('click', function (e) {
        let target = e.target
        while (target != null) {
            if (target instanceof HTMLAnchorElement) {
                const href = target.href

                // check if link is external
                const hrefOrigin = new URL(href).origin
                const isExternal = window.location.origin !== hrefOrigin
                if (
                    href &&
                    ['http://', 'https://', 'mailto:', 'tel:'].some((v) =>
                        href.startsWith(v)
                    ) &&
                    isExternal
                ) {
                    // @ts-ignore
                    window.__TAURI_INVOKE__('tauri', {
                        __tauriModule: 'Shell',
                        message: {
                            cmd: 'open',
                            path: target.href,
                        },
                    })
                    e.preventDefault()
                }
                break
            }
            // @ts-ignore
            target = target.parentElement
        }
    })
}

// @ts-ignore
if (window.__TAURI__) {
    if (
        document.readyState === 'complete' ||
        document.readyState === 'interactive'
    ) {
        __openLinks()
    } else {
        window.addEventListener(
            'DOMContentLoaded',
            function () {
                __openLinks()
            },
            true
        )
    }
}

// if (
//     typeof window !== 'undefined' &&
//     process.env.NODE_ENV === 'development'
//     // && /VIVID_ENABLED=true/.test(document.cookie)
// ) {
//     import('vivid-studio').then((v) => v.run())
//     import('vivid-studio/style.css')
// }

// strict mode breaks yjs for some reason
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
)
