import { IS_DEV, SEGMENT_WRITE_KEY, supabase } from '@/config'
import { ProfileId } from '@backend/sharedTypes/schema'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { Session } from '@supabase/supabase-js'
import { makeAutoObservable, runInAction } from 'mobx'
import posthog from 'posthog-js'
import { createContext, useContext, useEffect } from 'react'
import * as Sentry from '@sentry/react'

// We can export this instance to share with rest of our codebase.
export const analytics = AnalyticsBrowser.load({
    writeKey: SEGMENT_WRITE_KEY,
})
export interface Profile {
    id: ProfileId
    updated_at: string | null
    username: string | null
    full_name: string | null
    avatar_url: string | null
    website: string | null
    is_admin: boolean
    passive_autocomplete_enabled: boolean
    spellcheck_enabled: boolean | null
    timeout: number | null
    is_waitlisted: boolean | null
    gdrive_enabled: boolean
    daily_messages: number
    daily_messages_expiration: string
}

class SessionStore {
    profile: Profile | null = null
    session: Session | null = null
    sessionLoading = true

    constructor() {
        makeAutoObservable(this)
    }

    refreshProfile(userId: ProfileId) {
        supabase
            .from('profiles')
            .select()
            .eq('id', userId)
            .single()
            .then(async ({ data }) => {
                if (!data) return

                if (data.daily_messages_expiration < new Date().toISOString()) {
                    data.daily_messages = 0
                }

                // we need run in action here because the callback executes outside of the context
                runInAction(() => {
                    this.profile = { ...data }
                })

                if (!IS_DEV) {
                    analytics.identify(data?.id, {
                        ...data,
                        email: this.session?.user?.email,
                        created_at: this.session?.user?.created_at,
                    })

                    posthog.identify(data?.id, {
                        ...data,
                        email: this.session?.user?.email,
                        created_at: this.session?.user?.created_at,
                        email_confirmed_at:
                            this.session?.user?.email_confirmed_at,
                    })

                    Sentry.setUser({
                        ip_address: '{{auto}}',
                        username: data?.username ?? undefined,
                        id: data?.id,
                        email: this.session?.user?.email,
                    })
                }
            })
    }
}

export const sessionState = new SessionStore()
export const SessionContext = createContext<SessionStore>(sessionState)

export const SessionProvider: React.FC<{ children?: React.ReactNode }> =
    // eslint-disable-next-line mobx/missing-observer
    ({ children }) => {
        useEffect(() => {
            supabase.auth.getSession().then(({ data: { session } }) => {
                runInAction(() => {
                    sessionState.session = session
                    sessionState.sessionLoading = false
                    session?.user?.id &&
                        sessionState.refreshProfile(session?.user?.id)
                })
            })

            supabase.auth.onAuthStateChange((_event, session) => {
                runInAction(() => {
                    sessionState.session = session
                    session?.user?.id &&
                        sessionState.refreshProfile(session?.user?.id)
                })
            })
        }, [])

        return (
            <SessionContext.Provider value={sessionState}>
                {children}
            </SessionContext.Provider>
        )
    }

export const useSessionContext = () => {
    return useContext(SessionContext)
}
