import { Database } from '@/models/database.types'
import { ThemeSize } from '@backend/sharedTypes/schema'
import { createClient } from '@supabase/supabase-js'
import axios from 'axios'

const envRequired = (envVar: string): string => {
    const value = import.meta.env[envVar]
    if (!value) {
        throw new Error(`Missing required env var: ${envVar}`)
    }
    return value
}

const SUPABASE_URL = envRequired('VITE_APP_SUPABASE_URL')
const SUPABASE_ANON_KEY = envRequired('VITE_APP_SUPABASE_ANON_KEY')

export const API_ENDPOINT_WS = envRequired('VITE_APP_API_ENDPOINT_WS')
export const API_ENDPOINT_HTTP = envRequired('VITE_APP_API_ENDPOINT_HTTP')

export const SEGMENT_WRITE_KEY = envRequired('VITE_APP_SEGMENT_WRITE_KEY')

export const IS_DEV = import.meta.env.DEV
export const DISCORD_LINK = 'https://discord.gg/ECRn86V7U9'
export const TARGET = envRequired('VITE_APP_TARGET')

export const CHAT_SCROLL_OFFSET = 200

export const supabase = createClient<Database>(
    SUPABASE_URL,
    SUPABASE_ANON_KEY,
    {
        auth: {
            autoRefreshToken: true,
            persistSession: true,
        },
    }
)

export const apiClient = axios.create({
    baseURL: API_ENDPOINT_HTTP,
})

export const UNTITLED_PAGE_TITLE = 'Untitled document'

apiClient.interceptors.request.use(
    async (config) => {
        if (config.headers) {
            const { data } = await supabase.auth.getSession()
            const token = data.session?.access_token
            // @ts-ignore
            config.headers['Authorization'] = `Bearer ${token}`

            // disabling for now because this messes with sessions
            // const traceId = Sentry.getCurrentHub()
            //     .getScope()
            //     ?.getSpan()
            //     ?.toTraceparent()
            // // @ts-ignore
            // config.headers['sentry-trace'] = traceId
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

export const RELEASE_ID = import.meta.env.VITE_APP_RELEASE_ID
export const GOOGLE_OAUTH_CLIENT_ID = import.meta.env
    .VITE_APP_GOOGLE_OAUTH_CLIENT_ID
export const LATEST_RELEASE_ENDPOINT = 'https://releases.orchard.ink/latest'

export const SPELLCHECK_SUGGESTION_ELEM_CLASS = 'spellcheck-suggestion'
export const SIDEBAR_ELEM_ID = 'sidebar'

export const FONTS_MAP: { [key: string]: string } = {
    // serif: 'font-serif',
    serif: 'font-serifeditor',
    // sans: 'font-sans',
    sans: 'font-sanseditor',
    mono: 'font-monoeditor',
}

export const LEADING_MAP: { [key in ThemeSize]: string } = {
    xs: 'leading-[1rem]',
    sm: 'leading-[1.5rem]',
    md: 'leading-[2rem]',
    lg: 'leading-[2.5rem]',
    xl: 'leading-[3rem]',
}

export const PARAGRAPH_SPACING_MAP: { [key in ThemeSize]: string } = {
    xs: 'space-y-[0.5rem]',
    sm: 'space-y-[0.75rem]',
    md: 'space-y-[1rem]',
    lg: 'space-y-[1.5rem]',
    xl: 'space-y-[2rem]',
}

export const WIDTH_MAP: { [key in ThemeSize]: string } = {
    xs: 'max-w-[20rem]',
    sm: 'max-w-[30rem]',
    md: 'max-w-[40rem]',
    lg: 'max-w-[50rem]',
    xl: 'max-w-[60rem]',
}

export const PASSIVE_AUTO_EXT_ID = 'passiveAutocomplete'

// make sure this is the same as in the backend!
export const YDOC_DOCUMENT_KEY = 'default'
